#hero{
  background-color:black;
}

#carousel-title{
  position:absolute;
  width:40%;
  top:35vh;
  left:120px;
}

#carousel #header{
  background: transparent;
}

#carousel #menu-inner{
  background:transparent;
}

#carousel #header.menu-is-open{
  background-color:black;
}

header.menu-is-open #menu-inner{
  overflow:hidden;
}

.uppercase{
  text-transform:uppercase;
}

.header-wrapper{
	width:100%;
	min-height: 100%; /* Min-height 100 % (for Modern Browsers) */
	height: auto !important; /* important (for modern Browsers)*/
	height: 100%; /* IE bug */
	overflow: hidden !important; /* Firefox Scroll*/
}

.header-image{
	width: 100%;
	height: 100%;
	position: absolute;
}
.header-slideshow .slide{
	width:100%;
	height:100%;
	position: absolute;
	background-size: cover;
}

#slide-1{
		background-image: url("../../img/header-slideshow/large/slide-1.jpg");
		background-attachment: inherit;
		background-position: 48% 48%;
}

#slide-2{
		background-image: url("../../img/header-slideshow/large/slide-2.jpg");
		background-color:black;
		background-attachment: inherit;
		background-position: 48% 48%;
}

#slide-3{
	background-image: url("../../img/header-slideshow/large/slide-3.jpg");
		background-color:black;
		background-attachment: inherit;
		background-position: 48% 48%;
}


header[role="banner"]{
	position:absolute;
	width:100%;
	height:inherit;
	top:35%;
	text-align: center;
}

header[role="banner"] > p{
	width:50%;
	margin-left:25%;
}

#fb-logo{
  width:15px;
}

.socialmedia-widget p{
  font-size:8px;
}

@media screen and (max-width: 1280px){

	#slide-1{
			background-image: url("../../img/header-slideshow/small/slide-1.jpg");
	}

	#slide-2{
			background-image: url("../../img/header-slideshow/small/slide-2.jpg");
	}

	#slide-3{
		background-image: url("../../img/header-slideshow/small/slide-3.jpg");
	}

}

@media only screen and (max-width: 768px) {

  #carousel-title{
    position:absolute;
    width:auto;
    left:0px;
    top:35vh;
    padding-left:35px;
    padding-right:35px;
  }

  #carousel #header.menu-is-open{
    background-color:transparent;
  }

  #carousel #menu-inner{
    background:black;
  }

  #menu-inner nav#main-nav{
    overflow:scroll;
    overflow-x:hidden;
  }

  #carousel #header.text-light::after{
    background: none;
  }

}
